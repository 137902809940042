<template>
  <v-sheet class="my-10" elevation="5">
    <v-row class="d-flex justify-center align-center">
      <v-col cols="6" class="d-lg-block d-none">
        <div class="text-center">
          <h1 class="text-h3 text-green-darken-1 font-weight-bold">LIFELINE</h1>
          <v-divider>
            <p class="text-subtitle-1 text-medium-emphasis">
              Think Solutions; think Lifeline!
            </p>
          </v-divider>
        </div>
      </v-col>

      <v-col class="shadow-two" cols="12" lg="6">
        <h3 class="text-body-secondary font-weight-bold pt-3">About Us</h3>

        <v-sheet class="text-body-1 pa-5 pa-lg-10">
          <div class="text-lg-justify text-start">
            <p class="first">
              We are an educational hub in the heart of Tamale, Northern Region
              of Ghana. Incorporated in 2019, we provide a wide range of
              educational solutions.
            </p>

            <p>
              We specialize in providing tailored solutions to the myriad
              educational challenges encountered by schools, parents, and
              students.
            </p>

            <p>
              Our solutions include home tuition, educational guidance and
              counselling, and educational consultancy.
            </p>

            <NuxtLink to="/about">Read More...</NuxtLink>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      length: 3,
      onboarding: 0,
    };
  },
};
</script>

<style scoped>
.about {
  height: 24rem;
  /* padding: 5% 2%; */
}

.about-desc p {
  line-height: 1.3;
  font-family: "Kanit", "Lora", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.1rem;
  padding: 10px 0;
  /* height: 500px; */
  text-align: justify;
}
.first::first-letter {
  font-size: 2rem;
  /* color: black; */
  font-weight: bolder;
  /* padding: 1rem 0; */
  /* font-style: oblique; */
}

@media only screen and (max-width: 600px) {
  .about-desc {
    font-size: 0.99rem;
  }

  .about {
    height: 25rem;
  }

  .about-desc {
    /* line-height: 1.7; */
    font-size: 1rem;
    text-align: start;
  }
}
</style>
